import { useRef, useState } from "react";
import {
  BotaoImpressaoWeb,
  Box,
  Grid,
  IconButton,
  Loading,
  ModeloNfce,
  Tooltip,
  Typography,
} from "../../components";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import "./pdv-style.scss";
import {
  BancosCaixasResponsaveisService,
  BancosCaixasService,
  CaixaService,
  ColaboradorService,
  CondicoesPagamentoService,
  MeioPagamentoService,
  NfSaidaService,
  OperacaoFiscalService,
  OrigemMercadoriaService,
  SessionService,
  UnidadeComercialService,
  history,
  notification,
} from "../../services";
import { InputErros, PermissoesHelper, StorageHelper } from "../../helpers";
import { useEffect } from "react";
import { formatDate, formatPrice, responseErros } from "../../utils";
import {
  CabecalhoPdv,
  IdentificarClienteDialog,
  InformacaoComplementarDialog,
  OperacoesCaixaDialog,
  ImportarPedidoPdvDialog,
  AtalhosDialog,
  PdvClienteView,
  PdvProdutoView,
  PdvTotalizadores,
  NotasCreditoDialog,
} from "./components";
import { NfSaida, NfSaidaItem } from "../../entities";
import {
  compararPagamentosTotalNfSaida,
  totalPagamentos,
  totalVenda,
  verificaBaixaAutomatica,
} from "../../utils";
import { nfSaidaValidator } from "./middlewares";
import shortid from "shortid";
import Mousetrap from "mousetrap";
import { getListaPagamentoPadrao, impressaoNfce } from "../../entities/pdv";
import ModeloNfceSecundaria from "../../components/modelo-nfce-secundaria/modelo-nfce-secundaria.component";

const permissoesHelper = new PermissoesHelper();
const sessionService = new SessionService();
const storageHelper = new StorageHelper();
const caixaService = new CaixaService();
const unidadeComercialService = new UnidadeComercialService();
const origemMercadoriaService = new OrigemMercadoriaService();
const meioPagamentoService = new MeioPagamentoService();
const condicoesPagamentoService = new CondicoesPagamentoService();
const operacaoFiscalService = new OperacaoFiscalService();
const bancosCaixasService = new BancosCaixasService();
const nfSaidaService = new NfSaidaService();
const bancosCaixasResponsaveisService = new BancosCaixasResponsaveisService();
const colaboradorService = new ColaboradorService();

let pdvEntity = new NfSaida({
  dataEmissao: formatDate.toSend(new Date()),
  modalidadeFrete: "mfSemFrete",
  freteCompoeNota: false,
});

const PdvView = () => {
  const apiRef = useGridApiRef();
  const gridRef = useRef();
  const [loading, setLoading] = useState(false);
  const [bancoCaixa, setBancoCaixa] = useState(null);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [operacaoFiscal, setOperacaoFiscal] = useState({});
  const [operacaoFiscalSecundaria, setOperacaoFiscalSecundaria] = useState({});
  const [venda, setVenda] = useState(pdvEntity);
  const [produto, setProduto] = useState(null);
  const [item, setItem] = useState({});
  const [isProdutoEdit, setIsProdutoEdit] = useState(false);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [condicaoSelecionada, setCondicaoSelecionada] = useState(null);
  const [unidadeList, setUnidadeList] = useState([]);
  const [origensMercadoriasList, setOrigensMercadoriasList] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [dataAberturaCaixa, setDataAberturaCaixa] = useState(null);
  const [listaProdutosVenda, setListaProdutosVenda] = useState([]);
  const [listaPagamentosVenda, setListaPagamentosVenda] = useState([]);
  const [pagamentoSelecionado, setPagamentoSelecionado] = useState(null);
  const [userLog, setUserLog] = useState({});
  const [configuracoes, setConfiguracoes] = useState(null);
  const [dadosImpressao, setDadosImpressao] = useState(null);
  const [openDialogPagamentos, setOpenDialogPagamentos] = useState(false);
  const [openDialogIdentificarCliente, setOpenDialogIdentificarCliente] =
    useState(false);
  const [openDialogOperacoesCaixa, setOpenDialogOperacoesCaixa] =
    useState(false);
  const [openDialogListaPagamentos, setOpenDialogListaPagamentos] =
    useState(false);
  const [openImportarPedidoDialog, setOpenImportarPedidoDialog] =
    useState(false);
  const [openAtalhosDialog, setOpenAtalhosDialog] = useState(false);
  const [mudarUsuario, setMudarUsuario] = useState(false);
  const { empresa: dadosEmpresa, usuario: dadosUsuario } =
    sessionService.getEmpresaAndUsuario();
  const [impressaoFiscal, setImpressaoFiscal] = useState(false);
  const [listaPagamentoPadrao, setListaPagamentoPadrao] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const [
    openDialogInformacaoComplementar,
    setOpenDialogInformacaoComplementar,
  ] = useState(false);
  const [notasCredito, setNotasCredito] = useState([]);
  const [listaNotasCredito, setListaNotasCredito] = useState([]);
  const [openNotasCreditoDialog, setOpenNotasCreditoDialog] = useState(false);
  const [meioPagamentoCredito, setMeioPagamentoCredito] = useState({});
  const [colaboradorVendedor, setColaboradorVendedor] = useState(null);
  const [atalhos, setAtalhos] = useState([
    { atalho: "F1", nome: "AJUDA" },
    { atalho: "F4", nome: "BUSCAR PRODUTO" },
    { atalho: "Ctrl + I", nome: "IDENTIFICAR CLIENTE" },
    { atalho: "Ctrl + O", nome: "OPERAÇÕES CAIXA" },
    { atalho: "Ctrl + P", nome: "LISTA DE PAGAMENTOS" },
    { atalho: "Ctrl + Alt + F11", nome: "FINALIZAR VENDA SECUNDÁRIA" },
    { atalho: "Ctrl + Alt + F12", nome: "FINALIZAR VENDA PRIMÁRIA" },
    { atalho: "Ctrl + Alt + Enter", nome: "FINALIZAR VENDA" },
    { atalho: "Ctrl + Alt + C", nome: "LIMPAR CARRINHO" },
  ]);
  const nfSaidaValidatorService = nfSaidaValidator(operacaoFiscal);
  const UNAUTHORIZED = 401;
  const market =
    "https://siaflite-blob-bfesffcjhagbc4f6.z01.azurefd.net/imagem-publica/supermarket.gif";
  const dadosImpressaoRef = useRef(dadosImpressao);

  useEffect(() => {
    dadosImpressaoRef.current = dadosImpressao;
  }, [dadosImpressao]);

  useEffect(() => {
    validarToken();
    buscarDadosIniciais();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToBottom = () => {
    const scroller = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    );
    if (scroller?.length) {
      setTimeout(() => {
        scroller[0].scrollTop = scroller[0].scrollHeight;
      }, 100);
    }
  };

  useEffect(() => {
    if (listaProdutosVenda?.length) scrollToBottom();
  }, [listaProdutosVenda]);

  const dadosProdutosColunas = [
    {
      field: "codigo",
      headerName: "Cod",
      flex: 150,
      headerClassName: "table-pdv-header",
      sortable: false,
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 600,
      headerClassName: "table-pdv-header",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="my-2">
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              {params.value}
            </Typography>
            <span
              style={{
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: "normal",
              }}
            >
              Qtd: {params.row?.quantidade} UNID X Vl.Unit:{" "}
              {formatPrice(params.row?.subtotal)} | Desc:{" "}
              {formatPrice(params.row?.desconto || 0)} | Acrésc:{" "}
              {formatPrice(params.row?.acrescimo || 0)} | Est:{" "}
              {params.row?.estoqueAtual} | Cod.barra: {params.row?.codigoBarra}
            </span>
          </div>
        );
      },
    },
    {
      field: "subtotal",
      headerName: "Vlr. Total",
      headerClassName: "table-pdv-header",
      flex: 150,
      sortable: false,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "acoes",
      headerName: "Ações",
      sortable: false,
      headerClassName: "table-pdv-header",
      flex: 150,
      disableClickEventBubbling: true,
      hideable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={() => {
                handleEditarProduto(params.id);
              }}
            >
              <Tooltip title="Editar" arrow placement="right">
                <i className="ph-fill ph-pencil" style={{ fontSize: 18 }}></i>
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleRemoverProduto(params.id);
              }}
            >
              <Tooltip title="Excluir" arrow placement="right">
                <i className="ph-fill ph-trash" style={{ fontSize: 18 }}></i>
              </Tooltip>
            </IconButton>
          </div>
        );
      },
    },
  ];

  const atalhosPdv = [
    {
      keys: ["ctrl+i", "command+i"],
      action: () => {
        identificarCliente();
        return false;
      },
    },
    {
      keys: ["ctrl+o", "command+o"],
      action: () => {
        setOpenDialogOperacoesCaixa(true);
        return false;
      },
    },
    {
      keys: ["ctrl+p", "command+p"],
      action: (e) => {
        e.preventDefault();
        setOpenDialogListaPagamentos(true);
        return false;
      },
    },
    {
      keys: ["f1"],
      action: () => {
        setOpenAtalhosDialog(true);
        return false;
      },
    },
    {
      keys: ["f4"],
      action: (e) => {
        e.preventDefault();
        document.getElementById("produtoId").focus();
        return false;
      },
    },
    {
      keys: ["ctrl+alt+f11", "command+option+f11"],
      action: (e) => {
        e.preventDefault();
        handleSubmit(false);
        return false;
      },
    },
    {
      keys: ["ctrl+alt+f12", "command+option+f12"],
      action: (e) => {
        e.preventDefault();
        handleSubmit();
        return false;
      },
    },
    {
      keys: ["ctrl+alt+c", "₢", "command+option+c"],
      action: () => {
        if (!permissoesHelper.temPermisao("excluir-item-carrinho")) {
          return notification.alertaGenericos(
            "Você não tem permissão para limpar carrinho"
          );
        }
        limparPdvAtalho();
        return false;
      },
    },
    {
      keys: [
        "ctrl+alt+1",
        "command+option+1",
        "ctrl+alt+2",
        "command+option+2",
        "ctrl+alt+3",
        "command+option+3",
        "ctrl+alt+4",
        "command+option+4",
        "ctrl+alt+5",
        "command+option+5",
      ],
      action: (_, combo) => {
        const keyCommand = combo.replace(/[^0-9]/g, "");
        document.getElementById(`meio-pagamento-${keyCommand}`)?.click();
        return false;
      },
    },
  ];

  atalhosPdv.map(({ keys, action }) => Mousetrap.bind(keys, action));

  Mousetrap.stopCallback = function () {
    return false;
  };

  function identificarCliente() {
    document.getElementsByTagName("body")[0].focus();
    setOpenDialogIdentificarCliente(true);
  }

  const handleLogout = async () => {
    !mudarUsuario
      ? notification
          .confirmacao("Tem certeza que deseja sair?")
          .then(async (result) => {
            if (result.value === true) {
              handleLogoutAlterarUsuario();
              validarToken();
            }
          })
      : handleLogoutAlterarUsuario();
    setMudarUsuario(false);
  };

  const handleLogoutAlterarUsuario = async () => {
    setBancoCaixa(null);
    setListaProdutosVenda([]);
    storageHelper.removeLocal("token");
    storageHelper.removeLocal("rotinas");
    storageHelper.removeLocal("dados");
    storageHelper.removeSession("notificacao");
    storageHelper.removeLocal("bancoCaixaIdPdv");
  };

  const validarToken = async () => {
    const token = storageHelper.getLocal("token");
    if (!token) {
      history.push("/login-pdv");
      return;
    }
  };

  const abrirCaixa = async (bancoCaixaId) => {
    setLoading(true);
    const resultCaixa = await caixaService.cadastrar({
      data: new Date(),
      bancoCaixaId,
      aberto: true,
    });
    setLoading(false);
    if (resultCaixa.isAxiosError) {
      if (resultCaixa.response?.status === UNAUTHORIZED) {
        validarToken();
      } else {
        responseErros(resultCaixa);
      }
      return;
    }
    setDataAberturaCaixa(formatDate.toSend(new Date()));
    buscarBancoCaixa(bancoCaixaId);
  };

  const buscarBancoCaixaResponsavel = async () => {
    setLoading(true);
    const user = await sessionService.getUsuario();
    const result = await bancosCaixasResponsaveisService.getAll();
    if (!result.isAxiosError) {
      const data = result.data.rows.filter(
        (item) => item.usuarioId === user.id && item.bancoCaixa.ativado === true
      );
      if (data?.length) {
        abrirCaixa(data[0].bancoCaixaId);
      } else
        notification.alertaGenericos(
          "Você não tem acesso a nenhum caixa. Entre em contato com o administrador do sistema"
        );
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarBancoCaixa = (bancoCaixaId) => {
    if (bancoCaixaId) {
      bancosCaixasService.getById(bancoCaixaId).then((result) => {
        if (result.isAxiosError) {
          if (result.response.status === UNAUTHORIZED) {
            validarToken();
          } else {
            responseErros(result);
          }
          return;
        }
        setBancoCaixa(result.data);
      });
    }
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarOrigensMercadoria = () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriasList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarConfiguracao = async () => {
    if (dadosEmpresa?.configuracoes) {
      const data = dadosEmpresa.configuracoes;
      setConfiguracoes(data);
      if (data.operacaoFiscalPdvSecundariaId) {
        buscarOperacaoFiscalId(
          data.operacaoFiscalPdvSecundariaId,
          setOperacaoFiscalSecundaria,
          false
        );
      }
      if (data?.operacaoFiscalPdvPadraoId) {
        await buscarOperacaoFiscalId(
          data.operacaoFiscalPdvPadraoId,
          setOperacaoFiscal
        );
      }
      return data;
    }
  };

  const buscarOperacaoFiscalId = async (
    id,
    setOperacaoFiscal,
    operacaoPadrao = true
  ) => {
    if (id) {
      operacaoFiscalService.getById(id).then((result) => {
        if (!result.isAxiosError) {
          setOperacaoFiscal(result.data);
          if (operacaoPadrao) {
            pdvEntity.setOperacaoFiscal(result.data);
            setVenda(pdvEntity);
          }
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarOperacoesFiscais = (filtros) => {
    const filtrosOperacoesFiscais = {
      ativado: true,
      nonPaginated: true,
      tipoOperacao: "SAIDA",
      finalidade: ["NAO_SE_APLICA", "NFE_NORMAL"],
      modeloDocumento: ["65", "01"],
      restritiva: true,
      porIntervalo: [],
      ...filtros,
    };
    operacaoFiscalService
      .getAllFiltroAvancado(filtrosOperacoesFiscais)
      .then((result) => {
        if (!result.isAxiosError) {
          setOperacaoFiscalList(result.data);
        } else {
          responseErros(result);
        }
      });
  };

  const buscarMeiosPagamentos = async function () {
    const filtros = {
      ativado: true,
      nonPaginated: true,
    };
    const result = await meioPagamentoService.getAll(filtros);
    if (!result.isAxiosError) {
      setMeioPagamentoList(
        result.data.filter(
          (meio) => meio.disponivelPdv || meio.nfePagamento === "fpCreditoLoja"
        )
      );
      setMeioPagamentoCredito(
        result.data.find((meio) => meio.nfePagamento === "fpCreditoLoja")
      );
      return result.data;
    } else {
      if (result.response.status === UNAUTHORIZED) {
        validarToken();
      } else {
        responseErros(result);
      }
    }
  };

  const buscarCondicoesPagamentos = () => {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    condicoesPagamentoService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setCondicaoPagamentoList(result.data);
      } else {
        if (result.response.status === UNAUTHORIZED) {
          validarToken();
        } else {
          responseErros(result);
        }
      }
    });
  };

  const onChangeAutocomplete = (name, value) => {
    if (name === "clienteId") {
      setClienteSelecionado(value);
      pdvEntity.setIdentificarCliente(value);
      setVenda(pdvEntity);
    }
    pdvEntity.setHandleChangeAutoComplete(name, value);
    setVenda(pdvEntity);
  };

  const validarAddProduto = (produto, item) => {
    if (!produto) {
      notification.alertaGenericos("Selecione um produto para adicionar");
      return true;
    }
    if (!item.unidade) {
      notification.erroValidacao("Unidade do produto");
      return true;
    }
    if (!item.quantidade) {
      notification.erroValidacao("Quantidade do produto");
      return true;
    }
    if (!item.valor) {
      notification.erroValidacao("Valor Unitário do produto");
      return true;
    }
  };

  const handleAdicionarProduto = async (produto, item) => {
    const produtoIsValid = validarAddProduto(produto, item);
    if (produtoIsValid) return;
    if (item?.itemEditado) {
      const produtoIndex = listaProdutosVenda.findIndex(
        (obj) => obj.id === produto.id
      );
      const listaProdutosVendaTemp = [
        ...listaProdutosVenda.map((obj, index) => {
          if (index === produtoIndex) {
            return {
              ...obj,
              ...item,
              id: shortid.generate(),
              subtotal: item.quantidade * item.valor,
            };
          }
          return obj;
        }),
      ];
      setListaProdutosVenda(listaProdutosVendaTemp);
      setListaPagamentosVenda([]);
      pdvEntity.setChangePagamentos([]);
      pdvEntity.setChangeItens(null, listaProdutosVendaTemp);
      setVenda(pdvEntity);
      setItem({});
    } else {
      const nfSaidaItem = new NfSaidaItem(
        item,
        venda.nfSaidaItens?.length,
        origensMercadoriasList,
        unidadeList
      );
      if (nfSaidaItem === null) {
        return notification.alertaGenericos("Produto sem imposto");
      }
      const listaProdutosVendaTemp = [
        ...listaProdutosVenda,
        {
          ...item,
          ...nfSaidaItem,
          id: shortid.generate(),
          produto: produto?.descricao,
          quantidade: item?.quantidade ?? 1,
          subtotal: item?.valor * (item?.quantidade ?? 1),
        },
      ];
      setListaProdutosVenda(listaProdutosVendaTemp);
      setListaPagamentosVenda([]);
      pdvEntity.setChangePagamentos([]);
      pdvEntity.setChangeItens(null, listaProdutosVendaTemp);
      setVenda(pdvEntity);
      setItem({});
    }
    document.getElementById("produtoId").focus();
    setIsProdutoEdit(false);
    setProduto(null);
    setItem(null);
    setListaProdutos([]);
  };

  const handleEditarProduto = async (id) => {
    const item = listaProdutosVenda.find((item) => item.id === id);
    if (item) {
      setIsProdutoEdit(true);
    }
    setProduto(item);
    setItem({
      ...item,
      itemEditado: true,
    });
  };

  const handleRemoverProduto = async (id) => {
    if (!permissoesHelper.temPermisao("excluir-item-carrinho")) {
      return notification.alertaGenericos(
        "Você não tem permissão para excluir item do carrinho"
      );
    }
    const data = listaProdutosVenda.filter((item) => item.id !== id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir?`
    );
    if (retornoAlerta.isConfirmed) {
      setListaProdutosVenda(data);
      pdvEntity.setChangeItens(null, data);
      setListaPagamentosVenda([]);
      pdvEntity.setChangePagamentos([]);
      setVenda(pdvEntity);
    }
  };

  const noRowsOverlay = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <img src={market} alt="imagem carrinho" style={{ width: "33vh" }} />
      </div>
    );
  };

  const verificaVendaPrazo = () => {
    const isBaixaAutomatica = verificaBaixaAutomatica(venda, meioPagamentoList);
    return !isBaixaAutomatica;
  };

  const validarNfSaida = (nfSaidaDto) => {
    if (
      operacaoFiscal?.modeloDocumento === "55" &&
      operacaoFiscal?.finalidade !== "DEVOLUCAO" &&
      !nfSaidaDto?.nfSaidaEnderecoCliente?.id
    ) {
      notification.alertaGenericos(
        "É necessario cadastro de endereço para salvar"
      );
      setLoading(false);
      return false;
    }
    const verificarVendaPrazo = verificaVendaPrazo();
    if (verificarVendaPrazo && !venda.clienteId) {
      notification.alertaGenericos(
        "É necessário informar cliente para vendas a prazo"
      );
      setLoading(false);
      return false;
    }
    const pagamentosTotalNfSaida = compararPagamentosTotalNfSaida(venda);
    if (pagamentosTotalNfSaida) {
      notification.alertaGenericos(
        "É necessário atualizar valor da(s) parcela(s)"
      );
      setLoading(false);
      return false;
    }
    if (
      Number(nfSaidaDto?.totalVenda) > 500 &&
      !nfSaidaDto?.clienteCnpjCpf &&
      operacaoFiscal?.modeloDocumento !== "01"
    ) {
      setLoading(false);
      identificarCliente();
      notification.alertaGenericos(
        "Vendas de valor superior a R$ 500 devem identificar cliente."
      );
      return false;
    }
    return true;
  };

  const handleSubmit = async (operacaoPadrao = true) => {
    setLoading(true);
    if (!operacaoPadrao && !operacaoFiscalSecundaria?.id) {
      notification.alertaGenericos("Operação fiscal secundária não informada");
      setLoading(false);
      return;
    }
    const operacao = operacaoPadrao ? operacaoFiscal : operacaoFiscalSecundaria;
    pdvEntity.setOperacaoFiscal(operacao);
    setImpressaoFiscal(operacao?.modeloDocumento !== "01");
    nfSaidaValidatorService
      .validate(pdvEntity, { abortEarly: false })
      .then(async () => {
        const isValid = validarNfSaida(pdvEntity);
        if (!isValid) return;
        const response = await nfSaidaService.cadastrar(pdvEntity);
        setLoading(false);
        if (!response.isAxiosError) {
          notification.cadastroSucesso();
          operacao?.modeloDocumento !== "01"
            ? await emitirAposCadastro(response.data)
            : await inserirDadosImpressaoCadastro(response.data);
        } else responseErros(response);
      })
      .catch(async (err) => {
        setLoading(false);
        if (err.inner[0].path === "nfSaidaPagamentos") {
          await processarPagamentos();
        }
        inputErros.set(err);
      });
  };

  const inserirDadosImpressaoCadastro = async (data) => {
    setLoading(true);
    const dadosNf = await buscarNfSaidaById(data.id);
    if (dadosNf) {
      const dadosCompletos = {
        ...dadosNf,
        meioPagamentoList,
        clienteSelecionado,
        informacoesAdicionais: venda?.informacoesAdicionais,
      };
      setLoading(false);
      await setDadosImpressaoAsync(dadosCompletos);
      imprimirAposCadastro(dadosCompletos);
    }
  };

  const emitirAposCadastro = async (data) => {
    const confirmacaoAlerta = await notification.confirmacaoGenericos({
      title: "Documento cadastrado com sucesso",
      text: `#${data.numeroDocumento}`,
      icon: "success",
      confirmButtonText: "EMITIR",
      cancelButtonText: "FECHAR",
      showCancelButton: true,
    });
    setLoading(true);
    if (confirmacaoAlerta.isConfirmed) {
      const result = await nfSaidaService.emitirNotaFiscal(data.id);
      if (!result.isAxiosError) {
        const dadosXml = await buscarNfSaidaDadosXml(data.id);
        if (dadosXml) {
          await setDadosImpressaoAsync(dadosXml);
          imprimirAposCadastro(dadosXml);
        }
        setLoading(false);
      } else {
        responseErros(result);
        handleInitPdv();
      }
    } else {
      handleInitPdv();
    }
  };

  const setDadosImpressaoAsync = (dados) => {
    return new Promise((resolve) => {
      setDadosImpressao(dados);
      const checkStateUpdate = () => {
        if (dadosImpressaoRef.current === dados) {
          resolve();
        } else {
          setTimeout(checkStateUpdate, 10);
        }
      };
      checkStateUpdate();
    });
  };

  const imprimirAposCadastro = async (dados) => {
    if (!configuracoes?.impressaoDiretaPdv) {
      const confirmacaoAlerta = await notification.confirmacaoGenericos({
        title: "Deseja imprimir?",
        icon: "success",
        confirmButtonText: "IMPRIMIR",
        cancelButtonText: "FECHAR",
        showCancelButton: true,
      });
      if (confirmacaoAlerta.isConfirmed) {
        setLoading(true);
        await impressaoNfce(dadosEmpresa, dados, impressaoFiscal);
      }
    } else {
      setLoading(true);
      await impressaoNfce(dadosEmpresa, dados, impressaoFiscal);
    }
    handleInitPdv();
    setLoading(false);
  };

  const buscarNfSaidaDadosXml = async (idCadastrado) => {
    const result = await nfSaidaService.getDadosXml(idCadastrado);
    if (!result.isAxiosError) {
      setDadosImpressao(result.data);
      return result.data;
    } else {
      responseErros(result);
    }
  };

  const buscarNfSaidaById = async (id) => {
    const result = await nfSaidaService.getById(id);
    if (!result.isAxiosError) {
      if (result.data) {
        const dados = {
          ...result.data,
          totalMercadoria: venda.totalMercadoria,
        };
        setDadosImpressao(dados);
        return dados;
      }
    } else {
      responseErros(result);
    }
  };

  const buscarPessoaVendedor = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const usuario = await sessionService.getUsuario();
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      setVendedorList(
        result.data
          .filter((vendedor) => vendedor?.pessoa)
          .map(({ pessoa, ...vendedor }) => ({
            ...vendedor,
            ...pessoa,
          }))
      );
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) => colaborador.usuarioId === usuario?.id
      );
      if (colaboradorVendedorUsuario) {
        pdvEntity.setColaboradorVendedorUsuario(
          colaboradorVendedorUsuario.pessoa.id,
          colaboradorVendedorUsuario.bancoCaixaId,
          permissoesHelper.temPermisao("utilizar-caixa-colaborador")
        );
        setColaboradorVendedor(colaboradorVendedorUsuario);
        setVenda(pdvEntity);
      }
    } else {
      responseErros(result);
    }
  };

  async function processarPagamentos(pagamento) {
    if (
      totalPagamentos(listaPagamentosVenda) >=
      totalVenda(venda, listaProdutosVenda)
    ) {
      notification.alertaGenericos("Valor total pago");
      return;
    }
    if (!listaProdutosVenda?.length) {
      notification.alertaGenericos("Insira um produto");
      return;
    }
    if (!listaNotasCredito.length) {
      return selecionarPagamento(pagamento);
    }
    const confirmacaoAlerta = await notification.confirmacaoGenericos({
      title: "Cliente com crédito",
      text: `Deseja utilizar nesta compra?`,
      icon: "warning",
      confirmButtonText: "SIM",
      cancelButtonText: "NÃO",
      showCancelButton: true,
    });
    if (confirmacaoAlerta.isConfirmed) {
      setOpenNotasCreditoDialog(true);
    } else {
      selecionarPagamento(pagamento);
    }
  }

  async function handleInitPdv() {
    pdvEntity = new NfSaida({
      dataEmissao: formatDate.toSend(new Date()),
      modalidadeFrete: "mfSemFrete",
      freteCompoeNota: false,
    });
    setVenda(pdvEntity);
    await buscarPessoaVendedor();
    await buscarConfiguracao();
    setClienteSelecionado(null);
    setListaProdutosVenda([]);
    setListaPagamentosVenda([]);
    setLoading(false);
    document.getElementById("produtoId").focus();
  }

  async function buscarDadosIniciais() {
    document.getElementById("produtoId").classList.add("mousetrap");
    await buscarPessoaVendedor();
    const configuracoes = await buscarConfiguracao();
    const meiosPagamento = await buscarMeiosPagamentos();
    preencherListaPagamentoPadrao(configuracoes, meiosPagamento);
    buscarBancoCaixaResponsavel();
    buscarCondicoesPagamentos();
    buscarUnidadesComerciais();
    buscarOrigensMercadoria();
    buscarOperacoesFiscais();
    document.getElementById("produtoId").focus();
  }

  async function preencherListaPagamentoPadrao(configuracoes, meiosPagamento) {
    const listaPagamentos = getListaPagamentoPadrao(
      configuracoes,
      meiosPagamento
    );
    setListaPagamentoPadrao(listaPagamentos);
    const atalhosPagamentos = listaPagamentos.map((pagamento, index) => ({
      atalho: `Ctrl + Alt + ${index + 1}`,
      nome: pagamento.descricao,
    }));
    if (!atalhos.includes(atalhosPagamentos)) {
      setAtalhos((prevState) => [...prevState, ...atalhosPagamentos]);
    }
  }

  async function limparPdvAtalho() {
    const confirmacaoAlerta = await notification.confirmacaoGenericos({
      title: "Deseja limpar carrinho?",
      icon: "warning",
      confirmButtonText: "SIM",
      cancelButtonText: "NÃO",
      showCancelButton: true,
    });
    if (confirmacaoAlerta.isConfirmed) {
      setLoading(true);
      await handleInitPdv();
      setLoading(false);
    }
  }

  const selecionarPagamento = (pagamento) => {
    setPagamentoSelecionado(pagamento);
    if (pagamento?.condicoesPagamento.length === 1) {
      setCondicaoSelecionada(pagamento.condicoesPagamento[0]);
    }
    setOpenDialogPagamentos(true);
  };

  return (
    <Box className="box w-100">
      <CabecalhoPdv
        listaProdutos={listaProdutos}
        setListaProdutos={setListaProdutos}
        handleLogout={handleLogout}
        dadosUsuario={dadosUsuario}
        setProduto={setProduto}
        permitirVendaEstoqueNegativo={configuracoes?.vendaEstoqueNegativo}
        unidadeList={unidadeList}
        origensMercadoriasList={origensMercadoriasList}
        setItem={setItem}
        setUserLog={setUserLog}
        handleAdicionarProduto={handleAdicionarProduto}
        setOpenDialogOperacoesCaixa={setOpenDialogOperacoesCaixa}
        setOpenImportarPedidoDialog={setOpenImportarPedidoDialog}
      />
      <Box>
        <Grid container className="h-100">
          <Grid item xs={6} className="px-5 py-4">
            {produto ? (
              <PdvProdutoView
                produto={produto}
                item={item}
                setItem={setItem}
                userLog={userLog}
                handleAdicionarProduto={handleAdicionarProduto}
                isProdutoEdit={isProdutoEdit}
              />
            ) : (
              <PdvClienteView
                venda={venda}
                setVenda={setVenda}
                setOperacaoFiscal={setOperacaoFiscal}
                operacaoFiscalList={operacaoFiscalList}
                meioPagamentoList={meioPagamentoList}
                condicaoPagamentoList={condicaoPagamentoList}
                vendedorList={vendedorList}
                clienteSelecionado={clienteSelecionado}
                setClienteSelecionado={setClienteSelecionado}
                setOpenDialogClienteAvulso={setOpenDialogIdentificarCliente}
                setOpenDialogInformacaoComplementar={
                  setOpenDialogInformacaoComplementar
                }
                onChangeAutocomplete={onChangeAutocomplete}
                inputErros={inputErros}
                setInputErro={setInputErro}
                openDialogPagamentos={openDialogPagamentos}
                setOpenDialogPagamentos={setOpenDialogPagamentos}
                listaPagamentosVenda={listaPagamentosVenda}
                setListaPagamentosVenda={setListaPagamentosVenda}
                listaProdutosVenda={listaProdutosVenda}
                setListaProdutosVenda={setListaProdutosVenda}
                openDialogListaPagamentos={openDialogListaPagamentos}
                setOpenDialogListaPagamentos={setOpenDialogListaPagamentos}
                pagamentoSelecionado={pagamentoSelecionado}
                setPagamentoSelecionado={setPagamentoSelecionado}
                condicaoSelecionada={condicaoSelecionada}
                setCondicaoSelecionada={setCondicaoSelecionada}
                processarPagamentos={processarPagamentos}
                vendedorObrigatorio={permissoesHelper.temPermisao(
                  "pdv-vendedor-obrigatorio"
                )}
                colaboradorVendedor={colaboradorVendedor}
                listaPagamentoPadrao={listaPagamentoPadrao}
                pdvEntity={pdvEntity}
              />
            )}
          </Grid>
          <Grid item xs={6} className="grid-content-pdv">
            <div
              id="produtos-table"
              className="d-flex flex-column justify-content-between border h-100"
            >
              <Box className="table-overflow-produtos">
                <DataGrid
                  ref={gridRef}
                  apiRef={apiRef}
                  rows={listaProdutosVenda}
                  columns={dadosProdutosColunas}
                  rowCount={listaProdutosVenda?.length}
                  paginationMode="client"
                  disableRowSelectionOnClick
                  slots={{
                    noRowsOverlay: noRowsOverlay,
                  }}
                  getRowHeight={() => "auto"}
                  disableColumnMenu
                  hideFooter
                  hideFooterPagination
                  hideFooterSelectedRowCount
                />
              </Box>
              <PdvTotalizadores venda={venda} handleSubmit={handleSubmit} />
            </div>
          </Grid>
        </Grid>
      </Box>
      <IdentificarClienteDialog
        open={openDialogIdentificarCliente}
        setOpen={setOpenDialogIdentificarCliente}
        setClienteSelecionado={setClienteSelecionado}
        setListaNotasCredito={setListaNotasCredito}
        setVenda={setVenda}
        pdvEntity={pdvEntity}
      />
      <InformacaoComplementarDialog
        open={openDialogInformacaoComplementar}
        setOpen={setOpenDialogInformacaoComplementar}
        venda={venda}
        setVenda={setVenda}
        pdvEntity={pdvEntity}
      />
      <OperacoesCaixaDialog
        openDialogOperacoesCaixa={openDialogOperacoesCaixa}
        setOpenDialogOperacoesCaixa={setOpenDialogOperacoesCaixa}
        dataAberturaCaixa={dataAberturaCaixa}
        bancoCaixa={bancoCaixa}
        setBancoCaixa={setBancoCaixa}
        setListaProdutosVenda={setListaProdutosVenda}
        meioPagamentoList={meioPagamentoList}
        validarToken={validarToken}
        setLoading={setLoading}
      />
      <ImportarPedidoPdvDialog
        openImportarPedidoDialog={openImportarPedidoDialog}
        setOpenImportarPedidoDialog={setOpenImportarPedidoDialog}
        setListaProdutosVenda={setListaProdutosVenda}
        setListaPagamentosVenda={setListaPagamentosVenda}
        operacaoFiscal={operacaoFiscal}
        meioPagamentoList={meioPagamentoList}
        setMeioPagamentoList={setMeioPagamentoList}
        operacaoFiscalList={operacaoFiscalList}
        setOperacaoFiscalList={setOperacaoFiscalList}
        setClienteSelecionado={setClienteSelecionado}
        pdvEntity={pdvEntity}
        setVenda={setVenda}
      />
      <AtalhosDialog
        open={openAtalhosDialog}
        setOpen={setOpenAtalhosDialog}
        atalhos={atalhos}
      />
      <NotasCreditoDialog
        open={openNotasCreditoDialog}
        setOpen={setOpenNotasCreditoDialog}
        listaNotasCredito={listaNotasCredito}
        setListaNotasCredito={setListaNotasCredito}
        totalVenda={(venda?.totalVenda || 0) - (venda?.totalPagamentos || 0)}
        notasCredito={notasCredito}
        setNotasCredito={setNotasCredito}
        setVenda={setVenda}
        meioPagamentoCredito={meioPagamentoCredito}
        listaPagamentosVenda={listaPagamentosVenda}
        setListaPagamentosVenda={setListaPagamentosVenda}
        pdvEntity={pdvEntity}
      />
      <BotaoImpressaoWeb
        LayoutImpressao={
          impressaoFiscal ? (
            <ModeloNfce data={dadosImpressao} empresa={dadosEmpresa} />
          ) : (
            <ModeloNfceSecundaria
              data={dadosImpressao}
              meioPagamentoList={meioPagamentoList}
              empresa={dadosEmpresa}
            />
          )
        }
      />
      <Loading loading={loading} />
    </Box>
  );
};

export default PdvView;
